
@import "@styles/index.scss";


.schedulingItemContainer {
    @include card;
    padding: 30px;
    display: flex;
    min-width: 400px;
}

.schedulingItemContent {
    flex-grow: 1;
}

@include smallerDevice {
    .schedulingItemContainer {
        max-width: 360px;
    }
}

.icon {
    font-size: 64px;
    margin-right: 4px;
}