$lightEm: #FFFFFF;
$gray-100Em: #F0F0F0;
$gray-200Em: #E8E8E8;
$gray-600Em: #BBBBBB;
$darkEm: #223768;
$blackEm: #000000;
$red-600Em: #ff0000;
$darkMidnightBlue: #003869;
$obsidianGray: #222222;

