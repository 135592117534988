@import "@styles/index.scss";

.container {
  padding: 12px;
}

.icon {
  font-size: 60px;
  color: $secondaryFontColor;
}

.detailedMessage {
  padding-top: 12px;
}

.openMeetingPageButtonSection {
  padding-top: 12px;
}

.progress {
  width: 100%;
}

.title {
  color: $secondaryFontColor;
}