@import "./colors";
$smallerDeviceWidth: 500px !default;

$primaryButtonColor: $darkEm;
$mainBackgroundColor: $gray-100Em;
$primaryFontColor: $blackEm;
$secondaryFontColor: $blackEm;
$accentFontColor: $blackEm;
$checkBoxHoverShadow: $darkEm;
$cardShadow: inset 0 0 0 2px $gray-200Em;

$bigFontSizeEm: 16px;

$borderRadius: 8px;
$buttonBorderRadius: 8px;
$footerBackground: $darkMidnightBlue;