@import "@styles/index.scss";

.button {
  border-radius: $buttonBorderRadius;
  background-color: $lightEm;
  padding: 10px 20px;
  border: 1px solid $blackEm;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }

}

.buttonInverted {
  @extend .button;
  background-color: $primaryButtonColor;
  border: 1px solid $primaryButtonColor;
  color: $lightEm;

  &:disabled {
    background-color: $gray-600Em;
    border: 1px solid $gray-600Em;
  }
}

.text {
  font-size: large;
  font-weight: 600;
}

.icon {
  font-size: xx-large;
}
