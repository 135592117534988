
@import "./variables";

@mixin card {
    border-radius: $borderRadius;
    border: 2px solid $lightEm;
    background-color: $lightEm;
}

@mixin smallerDevice() {
    @media (max-width: $smallerDeviceWidth) {
        @content;
    }
}