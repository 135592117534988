@import "@styles/index.scss";

.modal {
  border-radius: $borderRadius;
  position: relative;
  display: flex;
  align-items: center;
}

.content {
  margin: 20px;
}

.cancelIcon {
  color: $gray-600Em;
  margin-left: auto;
  margin-top: 8px;
  margin-right: 8px;
}
