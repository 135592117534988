
$Green600ColorEm: #009b47;
$NavyBlue900ColorEm: #003869;
$BlackColor95AlphaEm: #4a4a4a;

$primaryButtonColor: $Green600ColorEm;
$mainBackgroundColor: $NavyBlue900ColorEm;
$primaryFontColor: $lightEm;
$secondaryFontColor: $blackEm;
$accentFontColor: $Green600ColorEm;
$checkBoxHoverShadow: $Green600ColorEm;
