@import "@styles/index.scss";

// based on https://codepen.io/aaroniker/pen/PowZbgb

.wrapper {
  position: relative;
}

.checkbox {
  display: block;
  position: relative;

  margin: 0;
  padding: 0;

  outline: none;
  background: $lightEm;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: box-shadow 0.3s;

  -webkit-appearance: none;
  -moz-appearance: none;

  box-shadow: inset 0 0 0 2px $gray-200Em;

  &:hover {
    box-shadow: inset 0 0 0 2px $checkBoxHoverShadow;
  }

  &:checked {
    box-shadow: inset 0 0 0 64px $checkBoxHoverShadow;

    & + .tickMark {
      stroke-dasharray: 16.1 86.12;
      stroke-dashoffset: 102.22;
      animation: bounce 0.4s linear forwards 0.2s;
    }
  }
}

.tickMark {
  display: block;
  position: absolute;
  pointer-events: none;

  top: 0;
  left: 0;
  transform: scale(1) translateZ(0);

  fill: none;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: $lightEm;

  stroke-dasharray: 86.12;
  stroke-dashoffset: 86.12;
  transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
}

@keyframes bounce {
  50% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}


.checkboxSize {
  height: 24px;
  width: 24px;
}