@import "@styles/index.scss";

.container {
    @include card;
    padding: 30px;
  }

  .slotButton {
    padding: 4px;
  }

  .slot {
    font-size: medium;
    font-weight: 300;
  }