@import "@styles/index.scss";

.wrapper {
  --validation-input-initial-color: #{$gray-200Em};
  --validation-input-text-color: currentColor;
  --validation-input-hover-color: #{$darkEm};
  --validation-input-padding: 12px;
  --validation-input-border-width: 2px;

  display: flex;
  align-items: center;
  background-color: var(--validation-input-background-color);
  color: var(--validation-input-text-color);
}

.contentWrapper {
  flex: 4;
  background-color: inherit;
}

.inputWrapper {
  position: relative;
  background-color: inherit;
}

.input {
  box-sizing: border-box;
  width: 100%;
  padding: var(--validation-input-padding);
  font-size: $bigFontSizeEm;
  border: var(--validation-input-border-width) solid var(--validation-input-initial-color);
  border-radius: 8px;
  color: var(--validation-input-text-color);

  &:hover:not(:focus) {
    border: var(--validation-input-border-width) solid var(--validation-input-hover-color);
  }

  &:focus {
    outline: none;
    border: var(--validation-input-border-width) solid var(--validation-input-text-color);
  }
}

.error {
  color: $red-600Em;
  margin: 4px 8px;
}
