@import "@styles/index.scss";

.container {
  @include card;
  padding: 30px;
}

.actionButton {
  max-height: 48px;
  width: 160px;
}

@include smallerDevice {
  .container {
    padding: 10px;
  }
}