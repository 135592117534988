@import "@styles/index.scss";

.formWrapper {
    max-width: 680px;
    width: 100%;
}

.title {
    text-align: center;
}

.titleAccent {
    @extend .title;
    color: $accentFontColor !important;
}

.meetingDateIcon {
    font-size: 64px;
    margin-right: 4px;
}