.page {
    height: 100vh;
    //padding: 10px;
}

.chatButton {
    position: fixed;
    top: calc(45% - 32px);
    right: 25px;
    min-width: 0;
    padding: 0px 5px;
    z-index: 10;
}

.mobileChat {
    height: calc(100vh - 70px);
}
