@import "@styles/index.scss";

.appFooter {
    font-family: Roboto, sans-serif;
    font-size: 0.75rem;
    background-color: $footerBackground;
    text-align: center;
    padding: 10px 0px;

    a {
        color: white;
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }
}
